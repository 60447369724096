import React from 'react'
import Text from '@/utils/Text'
import Button from '@/components/buttons/Button'
import * as m from '@/utils/messages'
import { navigate } from 'gatsby'
import '@/style/main.css'

const LogoutSuccessPage = () => {

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="text-center">
                <Text
                    as="h1"
                    className="text-base font-h-light mb-lg text-center"
                >
                    {m.LOGOUT_SUCCESS}
                </Text>
                <Button
                    onClick={() => navigate('/')}
                    theme="primary"
                    className="bg-accent hover:bg-accent-60"
                >
                    {m.GO_TO_LOGIN}
                </Button>
            </div>
        </div>
    );
}

export default LogoutSuccessPage